/* @import 'bootstrap/dist/css/bootstrap.min.css'; */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

select option {
  color: #212529 !important;
  cursor: pointer !important;
}

#not-selectedId {
  color: lightgray !important;
}

#selectedId option:nth-child(2),
#not-selectedId option:nth-child(2) {
  color: lightgray !important;
}

.btn-extra-small {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.75rem !important;
  cursor: pointer;
}

div.table-responsive {
  position: relative !important;
  /* z-index: 2 !important; */
  min-height: 118px !important;
}

.fade-in {
  animation: fadeIn 1s ease-in-out both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* for ModuleIntroView - custom/client/my-courses/course-preview-components/ModuleIntroView.js */
.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  align-items: start;
}

@media (max-width: 1126px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr;
  }
}

.custom-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em 0.75em;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background-color: #253048;
  height: 22px;
  width: fit-content;
  border-radius: 12px;
  text-align: center;
  white-space: nowrap;
}

/* Pagination.js */
.custom-pagination .page-item .page-link {
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
}

.page-item.active .page-link {
  background-color: #e1a046 !important;
  color: white !important;
  border-color: #e1a046 !important;
}

a.page-link {
  background-color: white !important;
  color: #e1a046 !important;
  border-color: #e1a046 !important;
}
a.page-link:hover {
  background-color: #e1a046 !important;
  color: white !important;
  border-color: #e1a046 !important;
}

.dynamic-content img {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  max-height: 80vh;
}
/* ----------------------------------------------------------------------------- */

.video-modal-body {
  position: relative;
  width: 100%;
  /* padding: 20px; */
  background-color: white;
}

.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.video-wrapper iframe,
.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* =============================================================================== */
/* Page.js */
.page-styles {
  margin: 0px 96px;
}

@media (max-width: 1186px) {
  .page-styles {
    margin: 0px 48px;
  }
}
@media (max-width: 667px) {
  .page-styles {
    margin: 0px 0px;
  }
}

.custom-small-btn {
  padding: 2px 6px !important;
  font-size: 12px !important;
}
/* .ellipsis for course template cards */
.template-ellipsis-container {
  width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1286px) {
  .template-ellipsis-container {
    width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 1220px) {
  .template-ellipsis-container {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 1100px) {
  .template-ellipsis-container {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 1020px) {
  .template-ellipsis-container {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 992px) {
  .template-ellipsis-container {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 952px) {
  .template-ellipsis-container {
    width: 360px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 972px) {
  .template-ellipsis-container {
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 782px) {
  .template-ellipsis-container {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 768px) {
  .template-ellipsis-container {
    width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 575px) {
  .template-ellipsis-container {
    width: 420px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 530px) {
  .template-ellipsis-container {
    width: 380px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 488px) {
  .template-ellipsis-container {
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 432px) {
  .template-ellipsis-container {
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 388px) {
  .template-ellipsis-container {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ellipsis {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word; /* or word-break: break-all; */
  overflow-wrap: break-word;
  white-space: normal;
  max-height: 2.4em;
  line-height: 1.2em;
}
.break-words {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
/* custom modal size for Resourses View */
.custom-modal-size .modal-dialog {
  max-width: 90vw;
  max-height: 90vh;
}

.custom-modal-size .modal-body {
  height: calc(90vh - 100px);
}

.as-link-styles:hover {
  color: lightgrey;
  cursor: pointer;
  text-decoration: underline;
}

/* TestAnswers.js */
/* .answers-table {
  border-collapse: collapse; 
} */

.answers-table td,
.answers-table th {
  border: none;
  padding: 10px !important;
}

.answers-table .attempt-row {
  border-top: 1px solid grey;
  border-bottom: none !important;
}

.no-content .accordion-button::after {
  display: none !important;
}

.ant-popover-inner-content {
  overflow: hidden !important;
}
.ant-modal {
  width: 700px !important;
  height: 1050px !important;
}
/* .ant-modal-content{
width: 800px!important;
} */
